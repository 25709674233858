<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <logo />
      <b-col>
        <h6 class="brand-text text-primary ml-1 text-uppercase mt-1">
          County Government of
        </h6>
        <h4 class="brand-text text-primary ml-1 text-uppercase">Kericho</h4>
      </b-col>
    </b-link>

    <div class="misc-inner p-2 p-sm-3 pt-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">We are launching this soon 🚀</h2>
        <p class="mb-3">
          We're creating something awesome here. We'll update this page once
          it's perfect.
          {{
            allowSubscription
              ? "Please check back later or subscribe to get notified when it's ready!"
              : ''
          }}
        </p>

        <!-- form -->
        <b-form
          v-if="allowSubscription"
          inline
          class="row justify-content-center m-0 mb-2"
          @submit.prevent
        >
          <b-form-input
            id="notify-email"
            class="col-12 col-md-5 mb-1 mr-md-2"
            type="text"
            placeholder="john@example.com"
          />

          <b-button variant="primary" class="mb-1 btn-sm-block" type="submit">
            Notify
          </b-button>
        </b-form>

        <b-img fluid :src="imgUrl" alt="Coming soon page" />
      </div>
    </div>
  </div>
  <!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable object-curly-newline */
import { BLink, BForm, BFormInput, BButton, BImg, BCol } from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    Logo,
    BLink,
    BForm,
    BButton,
    BFormInput,
    BImg,
    BCol,
  },
  data() {
    return {
      allowSubscription: false,
      downImg: require('@/assets/images/pages/coming-soon.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
